import React from 'react';
import ProjectsPage from 'pages/Projects';

const Projects = () => {
  return (
    <>
      <ProjectsPage />
    </>
  );
};

export default Projects;
